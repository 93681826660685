import React, { useState, useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Container, Navbar, Nav, Button, Modal } from 'react-bootstrap';

import AOS from "aos";
import "aos/dist/aos.css";

import CreateAccountWelcome from './CreateAccountWelcome';
import { useHttpClient } from '../shared/http-hook';

import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import { hashString } from 'react-hash-string';

import LoadingSpinner from './LoadingSpinner';

const Welcome = () => {
    const [usersData, setUsersData] = useState();
    const { sendRequest } = useHttpClient();

    const [userInfo, setUserInfo] = useState();

    const getInfo = async () => {
        try {
            const response = await sendRequest('https://geolocation-db.com/json/');
            setUserInfo(response)
        } catch (err) {
            console.log(err)
        }
    }

    const getUsersData = async () => {
        try {
            const response = await sendRequest('./data/users.json');
            const result = response.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, 6);
            
            setUsersData(result)
        } catch (err) {
            console.log(err);
        }
    }

    
    useEffect(() => {
        getInfo();
        getUsersData();
    }, []);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);


    const createHandler = (info) => {
        console.log('axios call body empty')
    };

    const [searchParams, setSearchParams] = useSearchParams();
    const autologin = searchParams.get("autologin")
    const autoemail = searchParams.get("autoemail")
	const autousername = searchParams.get("autousername")
	const autoage = searchParams.get("autoage")
    const [alLoading, setAlLoading] = useState(false)
	
    const transaction_id = searchParams.get("aff_sub")
    const aff_sub1 = searchParams.get("aff_sub1");
    const aff_sub2 = searchParams.get("aff_sub2");
    const aff_sub3 = searchParams.get("aff_sub3");
    const aff_sub4 = searchParams.get("aff_sub4");
    const aff_sub5 = searchParams.get("aff_sub5");

    const [show, setShow] = useState(autologin === "on" ? false : true);
    const handleShow = () => {
        setShow(true); 
    }

    function openInNewTab(href) {
        Object.assign(document.createElement('a'), {
          target: '_blank',
          rel: 'noopener noreferrer',
          href: href,
        }).click();
    }

    const createMailWizz = async (ip) => {
        try {
          const response = await sendRequest(
            'https://gorillamediallc.com/onenightserver/api/createmailwizz',
            'POST',
            JSON.stringify({
                username: autousername ? autousername : "Newbie",
                email: autoemail,
                years: autoage ? autoage : 18,
                country_code: userInfo ? userInfo.country_code : '',
                ip: ip,
                aff_sub2: aff_sub2,
                AFFID: aff_sub5
            }),
            {
              'Content-Type': 'application/json'
            }
          );       
          
          console.log(response)

        } catch (err){
            console.log(err);
        }
    }

    const createDa = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onenightserver/api/createda',
              'POST',
              JSON.stringify({
                  username: autousername ? autousername : "Newbie",
                  email: autoemail,
                  ip: ip,
                  aff: transaction_id,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );            

            const formatdata = response.data.split("|");
            const url = formatdata[3];
            
            if(url && url.startsWith('https://')){
                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(url)
            } else {
                createTrawle(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createMirelia = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onenightserver/api/createmirelia',
              'POST',
              JSON.stringify({
                  email: autoemail,
                  ip: ip,
                  aff: transaction_id
              }),
              {
                'Content-Type': 'application/json'
              }
            );            
            
            const url = response.responsedata.url;
            
            if(url && url.startsWith('https://')){
                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(url)   
            } else {
                createDa(ip)
            }
  
          } catch (err){
              console.log(err);
          }
    }

    const createTrawle = async (ip) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onenightserver/api/createtrawle',
              'POST',
              JSON.stringify({
                  username: "Newbie",
                  email: autoemail,
                  ip: ip,
                  aff: transaction_id,
                  aff_sub2: aff_sub2
              }),
              {
                'Content-Type': 'application/json'
              }
            );          
            
            const url = response.data.login_url;

            if(url && url.startsWith('https://')){
                window.location.href = 'https://onenightconfidential.com';
                openInNewTab(url)
            } else {
                createPure(ip)
            }

          } catch (err){
              console.log(err);
          }
    }

    function randomGenerator() {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
      
        for (var i = 0; i < 10; i++)
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      
        return text.toLowerCase();
    }

    var randomstring = randomGenerator();
    var randomusername = randomGenerator();
    const createPure = async (ip) => {
        try {
            const response = await sendRequest(
                'https://gorillamediallc.com/onenightserver/api/createpure',
                'POST',
                JSON.stringify({
                    uname: randomusername,
                    email: autoemail,
                    password: randomstring,
                    ip_address: ip,
                    city: userInfo?.city,
                    state: userInfo?.state,
                    aff: transaction_id,
                    aff_sub2: aff_sub2
                }),
                {
                  'Content-Type': 'application/json'
                }
              );          
              
              if(response.data !== "\"OK\""){
                createMirelia(ip)
              }
        } catch (err) {
            console.log(err)
        }
    }

    //
    const checkDB = async (email) => {
        const response = await sendRequest(
            'https://gorillamediallc.com/onenightserver/stored/data/usersdb.json',
            'GET',
            null,
            {
              'Content-Type': 'application/json'
            }        
        )

        const result = response?.find((user) => user.email === email)

        let checked;
        if(result) {
            checked = true;
        } else {
            checked = false;

            const formObject = {
                email: email
            }
    
        }

        return checked
    }

    const emailAPI = async (email) => {
        try {
            const response = await sendRequest(
              'https://gorillamediallc.com/onenightserver/api/checkemailapi',
              'POST',
              JSON.stringify({
                  email: email
              }),
              {
                'Content-Type': 'application/json'
              }
            );

            return response.dataresponse;      
          } catch (err) {
              console.log(err)
          }
    }

    const setAutoLogin = async () => {
        
        if(autologin === "on"){
            setAlLoading(true)

            const ausername = autousername ? autousername : "Newbie";
            const aage = autoage ? autoage : 18;

            const emailapi = await emailAPI(autoemail);

            if(emailapi && emailapi.valid === false && emailapi.recent_abuse === true && emailapi.deliverability === "low"){
                alert('Email did not pass the request!')
                setAlLoading(false)
                window.open('https://www.gorillatrk1.com/cmp/2D2DH/RF39C/', '_blank');
                return; 
            }

            const dbRes = await checkDB(autoemail);

            if(dbRes === true){
                alert('Email already exist in our database!')
                setAlLoading(false) 
                return;               
            }

            let ip;
            let country_code;

            try {
                await axios.get('https://geolocation-db.com/json/').then( response => {
                    ip = response.data.IPv4;
                    country_code = response.data.country_code;
                    return {ip, country_code}
                })
                
                createMailWizz(ip)
                createMirelia(ip)
    
                if(transaction_id){
                    const hash = hashString(autoemail);
                    localStorage.setItem('transaction_id', transaction_id)
                    await axios.get(`https://www.gorillatrk1.com/?nid=1737&transaction_id=${transaction_id}&adv1=${hash}&sub2=${aff_sub2}&sub3=${aff_sub3}`)
                }

                localStorage.setItem('aff_sub1', aff_sub1)
                localStorage.setItem('aff_sub2', aff_sub2)
                localStorage.setItem('aff_sub3', aff_sub3)
                localStorage.setItem('aff_sub4', aff_sub3)
                localStorage.setItem('aff_sub5', aff_sub5)    

    
                localStorage.setItem('user', JSON.stringify({
                    username: ausername,
                    email: autoemail,
                    age: aage
                }))

            } catch (err) {
                setAlLoading(false)
                console.log(err)
            } finally {
                setTimeout(() => {
                    setAlLoading(false)
                    window.location.href = "/"
                }, 10000);
            }

        }
    }

    useEffect(() => {
            setAutoLogin()
    }, [autologin]);

    return (
        <>
            <Navbar collapseOnSelect  expand="lg" bg="dark" variant="dark" className="shadow-sm">
            <Container>
            <Navbar.Brand><img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="logo" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                <Nav.Link><i className="fa-solid fa-location-crosshairs"></i> {userInfo ? userInfo.city : 'Loading...'}</Nav.Link>
                </Nav>
                <Nav>
                <Nav.Link onClick={handleShow}><i className="fa-solid fa-video"></i> Live Cams</Nav.Link>
                <Nav.Link onClick={handleShow}><i className="fa-solid fa-street-view"></i> Meet & Fuck</Nav.Link>
                <Nav.Link onClick={handleShow}><i className="fa-solid fa-users"></i> Register</Nav.Link>
                </Nav>
            </Navbar.Collapse>
            </Container>
            </Navbar>

            {alLoading && <LoadingSpinner asOverlay />}

            <div className="landing-main">
                <Container>
                    <div className="landing-main-title" data-aos="fade-up">
                        <h1>Meet someone <span className="custom-purplemain">tonight!</span> <br /> Find one night hookups near you</h1>
                    </div>

                    <div className="landing-page-button">
                        <Button onClick={handleShow} variant="dark" className="shadow mt-3" data-aos="fade-up">GET STARTED FOR FREE</Button>
                    </div>

                    <Row className="margin-row">
                    {usersData && usersData.map((user, index) => {
                        return (
                        <React.Fragment key={index}>
                        <Col sm={4} data-aos="fade-up">
                            <div className="user-block position-relative">
                                <div className="verified">
                                    <i className="fa-solid fa-circle-check"></i>
                                </div>
                                <Row>
                                    <Col sm={4}> <center> <img src={user.image} className="img-fluid" alt={user.name} style={{ maxHeight: "100px" }}/> </center> </Col>
                                    <Col sm={8}>
                                        <p><i className="fa-solid fa-user"></i> {user.name}</p>
                                        <p><i className="fa-solid fa-calendar"></i> {user.age} years <small>old</small> </p>
                                        <p>{user.status === "online" ? <span className="online"><i className="fa-solid fa-signal"></i> Online</span> : <span className="offline"><i className="fa-solid fa-signal"></i> Offline</span>}</p>
                                    </Col>
                                    <hr />
                                    <Col sm={12}>
                                        <Button onClick={handleShow} variant="dark" className="w-50 mb-3 rounded-0"><i className="fa-solid fa-message"></i> SEND MESSAGE</Button> 
                                        <Button onClick={handleShow} variant="danger" className="w-50 mb-3 rounded-0"><i className="fa-solid fa-heart"></i> REQUEST DATE </Button> 
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        </React.Fragment>
                        );
                    })}
                    </Row>
                </Container>
            </div>

            <Modal show={show} centered keyboard={false} backdrop="static" className="custom-modalcontent">
                <Modal.Body className="position-relative">
                    <Row className="g-0">
                        <Col sm={12} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/modal-image.jpg)`, backgroundPosition: 'center', backgroundSize: 'cover', height: "550px", padding: "0px" }}>
                            <center> <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ height: '50px' }} alt="small logo" className="smalllogomodaltop" /> </center>

                            <CreateAccountWelcome  onCreate={createHandler} />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Welcome;