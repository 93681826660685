import React from 'react';

import { Container, Button } from 'react-bootstrap';

const Tos = () => {
    return (
        <>
        <div className="infopage-body">
        <Container>
            <a href="index.html"> <Button variant="dark" className="m-4"> <i className="fa-solid fa-arrow-left-long"></i> Back to Home </Button> </a>	
	        <div className="additional-page addbordercontent customcontent-addpages">
            <h1>Terms of Service / User Agreement / Disclaimer</h1>
            <p>This website and its component services (referred to herein as "Site" or "Service") are presented to you on the terms and conditions set forth below by https://onenightconfidential.com. By accessing or using this Service, you ("You") agree to each and every term and condition stated below, and as may be revised from time to time. By accessing or using this Site or Service, you hereby state and affirm that You have read, understood in full, and agree with all of the following terms of service ("Agreement" or "TOS"):</p>
        
            <h2>1. PRE-CONDITIONS FOR ACCESS TO SITE OR USE OF THE SERVICE</h2>
            <p>You must be of legal age to enter into binding agreements (such as this), meaning you must be at least 18 years of age (21 in some jurisdictions). This Site may contain images, language and sounds that are sexually erotic in nature. By accessing or using this Service, You represent and warrant that you are not offended by images of nudity, sexually charged language, and that You choose to be exposed to same herein. If you have a person under the age of majority in your household or who might otherwise gain access to this Site via your computer, the Company strongly encourages you to install filtering software to prevent access to this Site by a minor or a person who may be offended by its content. You represent and warrant that you will not allow a minor access to this Site. We do not make any warranty or representation regarding these products so We highly recommend You conduct responsible due diligence before installing or purchasing any online filter. Further, We cannot and will not provide technical support for these products. By using the Service, You expressly authorize the Company to communicate with you via electronic messaging, to the email address you have provided, regarding the Service, Service updates or information pertaining to the Service or your subscription.</p>
        
            <h2>2. FOR AMUSEMENT PURPOSES ONLY</h2>
            <p>The Service offered for Your personal, non-commercial use. You comprehend, acknowledge, and accept that our Service, presented in the style of an online dating service, is an entertainment service. All content is provided for the entertainment and amusement of You. Our Site does not guarantee that You will find a date, partner, or participant, or that you will meet any member, user or profile in person.</p>
        
            <h2>3. LIMITATION OF SERVICE</h2>
            <p>These General Terms of Service (hereinafter referred to as "ToS") represent the exclusive basis for all legal relationships established between us and you, related to the use of this Service.</p>

            <h2>4. POSTED CONTENT / LIMITS & RESTRICTIONS</h2>
            <p>The Company, Site and Service are Internet Service Providers and Online Service Providers as defined by U.S. federal law, including but not limited to the Copyright Act and the Communications Decency Act and Federal Regulations, and claim all immunities and privileges owing to or available to same. Nothing in this Agreement is intended to waive or remove any such immunities and privileges. You are solely responsible for any content you post or upload or transmit on the Site or through the Service, and you represent and warrant that you have all legal rights necessary to do so, including but not limited to rights under copyright.</p>
        
            <h2>5. INDEMNITY</h2>
            <p>You agree to indemnify and hold the Company, and its affiliates, directors, officers, employees, agents and representatives harmless from and against any claim or demand, including attorneys' fees, made by any third party due to or arising out of images, language or content you submit, post to, text or transmit through the Service, your use of the Service, your connection to the Service, your violation of the TOS, or your violation of any rights of another person or entity. You further agree that in the event of any action or claim brought against the Company due to or arising out of any images, language or content you submit, post to or transmit through the Service, your use of the Service, your connection to the Service, your violation of the TOS, or your violation of any rights of another, you will reimburse the Company for its reasonable attorneys' fees and costs incurred in the defense of such action or claim.</p>

            <h2>6. MODIFICATIONS TO SERVICE</h2>
            <p>You agree that the Company may at any time, in its sole discretion, modify, suspend or discontinue, temporarily or permanently, the Service (or any part thereof). You agree that the Company shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.</p>
        
            <h2>7. TERMINATION</h2>
            <p>Notwithstanding the fact that Company is an Internet Service Provider as defined under federal laws, you acknowledge and agree that the Company, in its sole discretion, may at any time terminate your profile, your access to the Site, and/or remove and discard any Content within the Service, for any reason, including, without limitation, for lack of use or if the Company believes that you have violated or acted inconsistently with the letter or spirit of the TOS or of any terms and conditions or rules posted on the site. You agree that any termination of your access to the Service under any provision of these TOS may be effected without prior notice, and acknowledge and agree that the Company may immediately deactivate or delete your account (or any part thereof) and all related information and files in your account and/or bar any further access to such files or the Service. Further, you agree that the Company shall not be liable to you or any third party for any termination of your access to, or removal of your information from, the Service.</p>
        
            <h2>8. DEALINGS WITH ADVERTISERS/MARKETING PARTNERS</h2>
            <p>Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Service, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such advertiser. You agree that the Company shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such</p>
			</div>            
        </Container>
        </div>
        </>
    )
}

export default Tos;