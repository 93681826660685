import React from 'react';

import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import { ShakeSlow } from 'reshake';

const DashboardStartMessage = () => {
    return (
        <>
        <div className="db-startmessage">
        <Link to="/dashboard/hotornot">
            <center>
                <img src={`${process.env.PUBLIC_URL}/images/start-image.png`} style={{ maxHeight: "300px" }} className="img-fluid" alt="Getting started" />
                <section>
                    <h2>Getting started</h2>
                    <p>Increase your activity</p>

                    <Row>
                        <Col sm={4}>
                            <ShakeSlow>
                            <div className="db-startmessagestep">
                                    <div className="number">1</div>
                                    <h2><i className="fa-solid fa-fire-flame-curved"></i></h2>
                                    <p>Play hor or not</p>
                            </div>
                            </ShakeSlow>
                        </Col>
                        <Col sm={4}>
                            <ShakeSlow>
                            <div className="db-startmessagestep">
                            <div className="number">2</div>
                                    <h2><i className="fa-solid fa-comments"></i></h2>
                                    <p>Send messages</p>
                            </div>
                            </ShakeSlow>
                        </Col>
                        <Col sm={4}>
                            <ShakeSlow>
                            <div className="db-startmessagestep">
                            <div className="number">3</div>
                                    <h2><i className="fa-solid fa-gem"></i></h2>
                                    <p> Get <b>BANG</b></p>
                            </div>
                            </ShakeSlow>
                        </Col>
                    </Row>
                </section>
            </center>
        </Link>
        </div>
        </>
    )
}

export default DashboardStartMessage;