import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, NavLink, Link, useLocation, useSearchParams } from 'react-router-dom';

import { Container, Row, Col, Navbar, Nav, Button, Modal } from 'react-bootstrap';

import { AuthContext } from '../shared/auth-context';

import AOS from "aos";
import "aos/dist/aos.css";

import DashboardMain from './pages/DashboardMain';
import DashboardProfile from './pages/DashboardProfile';
import DashboardHotornot from './pages/DashboardHotornot';
import DashboardVisitors from './pages/DashboardVisitors';
import DashboardMessages from './pages/DashboardMessages';
import DashboardMatches from './pages/DashboardMatches';
import DashboardLocal from './pages/DashboardLocal';
import DashboardMembers from './pages/DashboardMembers';
import DashboardMemberProfile from './pages/DashboardMemberProfile';
import DashboardTopPosts from './pages/DashboardTopPosts';
import DashboardLiveCams from './pages/DashboardLiveCams';

import DashboardTermsandConditions from './pages/DashboardTermsandConditions';
import DashboardPrivacyPolicy from './pages/DashboardPrivacyPolicy';

import DisplayAds from './DisplayAds';
import IframeModal from './helpers/IframeModal';
import CustomModal from './helpers/CustomModal';

import { useHttpClient } from '../shared/http-hook';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from 'axios';
import EmailModal from './helpers/EmailModal';

const Dashboard = () => {
    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();
    const local = JSON.parse(localStorage.getItem('user'))

    const [expanded, setExpanded] = useState(false);

    const [visitorsC, setVisitorsCount] = useState(0);
    const [messagesC, setMessagesCount] = useState(0);
    const [matchesC, setMatchesCount] = useState(0);


    const getCounts = async () => {
        try {
            const response = await sendRequest('https://gorillamediallc.com/onenightserver/api/getusersitems');

            const filter_visitors = response.user_items.filter((item) => item.type === "visitors" && item.member === local.username)
            if(visitorsC !== null) setVisitorsCount(filter_visitors.length)

            const filter_messages = response.user_items.filter((item) => item.type === "messages" && item.member === local.username)
            if(messagesC !== null) setMessagesCount(filter_messages.length)            

            const filter_matches = response.user_items.filter((item) => item.type === "matches" && item.member === local.username)
            if(matchesC !== null) setMatchesCount(filter_matches.length)        
        } catch (err) {
            console.log(err)
        }
        
    }

    useEffect(() => {
        getCounts();

        const interval = setInterval(() => {
            getCounts();
        }, 10500);
        return () => clearInterval(interval);       
    }, [sendRequest]);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);

    const [popData, setPopData] = useState();
    const [showPop, setShowPop] = useState(false)
    const handleShowPop = () => {
        setShowPop(true)
        fetchPop()
    }
    const handleClosePop = () => setShowPop(false)

    const fetchPop = async () => {
        try {
            const responseData = await axios.get('https://gorillamediallc.com/onenightserver/stored/data/ads_list.json');
            const maxData = await axios.get('https://gorillamediallc.com/onenightserver/stored/data/settings.json');

            const maxPop = maxData.data.settings.maxPop;

            const filterPop = responseData.data.filter((ad) => ad.type === 5);
            const resultPop = filterPop.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, maxPop);

            setPopData(resultPop[0])


        } catch (err) {
            console.log(err)
        }
    }

    const [showPopOneTime, setShowPopOneTime] = useState(true)
    const handleClosePopOneTime = () => setShowPopOneTime(false)

    let dashboardroutes = (
        <Routes>
          <Route path="/dashboard/profile" element={<DashboardProfile />}/>
          <Route path="/dashboard/hotornot" element={<DashboardHotornot />}/>
          {/* <Route path="/dashboard/visitors" element={<DashboardVisitors />}/> */}
          <Route path="/dashboard/messages" element={<DashboardMessages />}/>
          <Route path="/dashboard/matches" element={<DashboardMatches />}/>
          <Route path="/dashboard/local" element={<DashboardLocal />}/>
          <Route path="/dashboard/members" element={<DashboardMembers />}/>
          <Route path="/dashboard/member/:nick" element={<DashboardMemberProfile />}/>
          <Route path="/dashboard/members" element={<DashboardMembers />}/>
          <Route path="/dashboard/terms" element={<DashboardTermsandConditions />}/>
          <Route path="/dashboard/privacypolicy" element={<DashboardPrivacyPolicy />}/>
          <Route path="/dashboard/top-posts" element={<DashboardTopPosts />}/>
          <Route path="/dashboard/livecams" element={<DashboardLiveCams />}/>
          <Route path="*" element={<DashboardMembers />}/>
        </Routes>
    )

    const [iframeBtn, setIframeBtn] = useState(false);

    const [customBtn, setCustomBtn] = useState(false);
    const [customBtnType, setCustomBtnType] = useState();

    const notify = (props) => {
        toast(<div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          paddingLeft: 5
        }}
      >

        <a href={`https://www.gorillatrk1.com/3J67C/P7KF8P/?source_id=onc_sic${updatedlink}`} className="notfa" target="_blank" rel="noreferrer">
        <Row>
            <Col sm={3}> <img src={props.notf.image} className="img-fluid" style={{ maxHeight: "50px"}} alt="Photo" /> </Col>
            <Col sm={9}> <strong>{props.notf.name}</strong> <br /> <small>{props.message.substring(0, 50)+'...'}</small> <br /> <em style={{ color: "gray", fontSize: "12px" }}>{new Date().toLocaleString()}</em> </Col>
        </Row>
        </a>

      </div>)
    }

    const fetchRandomNotf = async () => {
        try {
            const responseData = await axios.get('/data/users.json');
            const axiosData = responseData.data;

            const resultNotf = axiosData.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, 1);

            const responseMessages = await sendRequest('https://gorillamediallc.com/onenightserver/stored/data/messages_list.json');
            const resultMessages = responseMessages.sort(function() {
              return .5 - Math.random()
            }).slice(0, 1);
            
            const random = Math.floor(Math.random() * resultMessages[0].messages.length);
            const random_message = resultMessages[0].messages[random];

            notify({ notf: resultNotf[0], message: random_message })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        var timeout;
        document.onmousemove = function(){
          clearTimeout(timeout);
          timeout = setTimeout(function(){ 
            fetchRandomNotf()
          }, 5000);
        }
    }, []);
    
    // useEffect(() => {
    //     setInterval(() => {
    //              var activeiframe = JSON.parse(localStorage.getItem('iframeactive')) || false;
    //              if(activeiframe === false && showPop === false && customBtn === false) handleShowPop()
    //     }, 20000);
    // }, []);

    const localEmailSystem = localStorage.getItem('emailsystem')
    const localCplSystem = localStorage.getItem('cplsystem')

    const aff_sub = localStorage.getItem('transaction_id')
    const aff_sub1 = localStorage.getItem('aff_sub1')
    const aff_sub2 = localStorage.getItem('aff_sub2')
    const aff_sub3 = localStorage.getItem('aff_sub3')
    const aff_sub4 = localStorage.getItem('aff_sub4')
    const aff_sub5 = localStorage.getItem('aff_sub5')

    // const link = `?sub1=${aff_sub1}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${aff_sub4}&sub5=${aff_sub5}`;
    const link = `?sub1=${aff_sub}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${aff_sub4}&sub5=${aff_sub5}`;
    const updatedlink = `?sub1=${aff_sub1}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${aff_sub4}&sub5=${aff_sub5}&transaction_id=${aff_sub}`;
    const emailsystemlink = `&visitor_id=${aff_sub1}&SUBID=${aff_sub2}&email=${aff_sub4}&AFFID=${aff_sub5}`

    // const displayLink = localEmailSystem === 'on' ? emailsystemlink : updatedlink
    const displayLink = link;
    
    // custom popover on click count
    const [popDataC, setPopDataC] = useState();
    const [showPopC, setShowPopC] = useState(false)
    const handleShowPopC = () => {
        setShowPop(true)
        fetchPopC()
    }
    const handleClosePopC = () => setShowPop(false)

    const fetchPopC = async () => {
        try {
            const responseData = await axios.get('https://gorillamediallc.com/onenightserver/stored/data/webads_list.json');

            const filterPop = responseData.data.filter((item) => item.origin === document.location.origin && item.type === "popover");
            const resultPop = filterPop.sort(function() { 
                return .5 - Math.random() 
            }).slice(0, 1);

            setPopDataC(resultPop[0])


        } catch (err) {
            console.log(err)
        }
    }

    const location = useLocation();
    useEffect(() => {
        setTimeout(() => {
            if(showPopOneTime === false) handleShowPop()
        }, 5000);        
    }, [location.pathname]);


    // click
    // useEffect(() => {
    //     document.body.addEventListener('click', openCA );
    
    //     return function cleanup() {
    //         window.removeEventListener('click', openCA );
    //     } 
    // },[]);
    
    // const [click, setClick] = useState(0)
    
    // let openCA = () => {
    //     setClick(click + 1)
    // }
    
    // useEffect(() => {
    //     if(click === 1) {
    //       window.open(`https://www.gorillatrk1.com/3J67C/F2FMSJ/${link}`, '_blank');
    //     }
    // }, [click]);
  
    const [customFullscreen, setCustomFullscreen] = useState(false);
    
    const [adsLinks, setAdsLinks] = useState();

    const fetchAds = async (type) => {
        try {
            const responseData = await axios.get('https://gorillamediallc.com/onenightserver/stored/data/ads_list.json');
            const allowedTypes = [6, 7, 8];
            const filteredAds = responseData?.data?.filter(ad => allowedTypes.includes(ad.type));
                        
            setAdsLinks(filteredAds);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchAds()
    }, []);

    // emailsystem
    const [searchParams, setSearchParams] = useSearchParams();
    const emailsystem = searchParams.get("emailsystem");
    
    useEffect(() => {
        if(emailsystem === "on") localStorage.setItem('emailsystem', 'on')
    }, [emailsystem]);

    const user = localStorage.getItem('user')
    const userInfo = user && JSON.parse(user)

    // const createAdLink = (type) => {
    //     let ad = adsLinks && adsLinks.find((ad) => ad.type === type)
        
    //     let adlink;
    //     let adtype;

    //     if(ad){
    //         if(ad.emailsystem === true && localEmailSystem === 'on'){
    //             if(userInfo.years < 30){
    //                 adlink = formatGeoLinks(ad.emaillink, ad, 'EmailLinkDefault', userGeo?.country_code)
    //                 adtype = 2
    //             } else if(userInfo.years > 30){
    //                 adlink = formatGeoLinks(ad.emaillinkolder, ad, 'EmailLinkOlder', userGeo?.country_code)
    //                 adtype = 2
    //             }
    //         } else if(ad.cplsystem === true && localCplSystem === 'on'){
    //             adlink = formatGeoLinks(ad.cpllink, ad, 'CplSystemLink', userGeo?.country_code)
    //             adtype = 1
    //         } else {
    //             if(ad.agesystem === true){

    //                 if(userInfo.years > 30){
    //                     adlink = formatGeoLinks(ad.agelink, ad, 'AgeSystemLink', userGeo?.country_code)
    //                     adtype = 1
    //                 }  else {
    //                     adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
    //                     adtype = 1
    //                 }

    //             } else if(ad.split === true) {

    //                 if(affPercent > countNumber){
    //                     adlink = formatGeoLinks(ad.redirection_aff, ad, 'LinkAff', userGeo?.country_code)
    //                     adtype = 1
    //                 } else {
    //                     adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
    //                     adtype = 1
    //                 }

    //             } else {
    //                 adlink = formatGeoLinks(ad.redirection, ad, 'Link', userGeo?.country_code)
    //                 adtype = 1
    //             }
    //         }
    //     }

    //     return adlink;
    // }

    const createAdLink = async (type) => {
        const responseData = await axios.get('https://gorillamediallc.com/onenightserver/stored/data/ads_list.json');
        const ad = responseData.data.find((item) => item.type === type)
    
        let adlink = ad.redirection;
        if(!adlink) {
            return;
        }
    
        return adlink;
    }

    const [primaryLinks, setPrimaryLinks] = useState();

    useLayoutEffect(() => {
        Promise.all([
            createAdLink(6),
            createAdLink(7),
            createAdLink(8),
        ]).then(([result1, result2, result3]) => {
            setPrimaryLinks({
            notification: result1,
            modal: result2,
            fucknow: result3,
          });
        });
    }, []);

    // adserver parse function
    function fixAdserver(adlink, type) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(adlink, 'text/html');
        const insElement = doc.querySelector('iframe');
    
        if (insElement) {
            insElement.setAttribute('src', insElement.getAttribute('src') + "&source=" + aff_sub2);
            return doc.body.innerHTML;
        }
    
        return adlink;
    }
        

    const [userGeo, setUserGeo] = useState();

    const getGeoInfo = async () => {
        try {
            const response = await axios.get('https://geolocation-db.com/json/');
            setUserGeo(response.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getGeoInfo()
    }, []);

    const formatGeoLinks = (currentlink, ad, type, currentcountry) => {
        const countries = ad.countries;
        const getLink = countries && countries.find((c) => c.code?.toUpperCase() === currentcountry?.toUpperCase() && c.id === type);

        let result;
        if(getLink){
            result = getLink.link
        } else {
            result = currentlink;
        }

        return result;
    }
    
    const currentYear = new Date().getFullYear();

    // EMAIL MODAL
    const [showEmailModal, setShowEmailModal] = useState(false);
    const localEmailModal = localStorage.getItem('emailmodal')

    useEffect(() => {
        let intervalId;
    
        if (localCplSystem === 'on' && auth.email && !localEmailModal) {
            intervalId = setInterval(() => {
                setShowEmailModal(true);
            }, 30000);
        }
    
        return () => {
            clearInterval(intervalId);
        };
    }, [localCplSystem, auth.email, localEmailModal]);

    return (
        <>
        <EmailModal show={showEmailModal} onClose={() => setShowEmailModal(false)} />

        {/* <a href={`${adsLinks && createAdLink(6)}${displayLink}`} className="notfa" target="_blank" rel="noreferrer"> */}
        <a href={`${primaryLinks && primaryLinks.notification}${displayLink}`} className="notfa" target="_blank" rel="noreferrer">
        <ToastContainer
            className="toaster-container"
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        </a>
    
        <IframeModal display={iframeBtn} custom_fullscreen={customFullscreen} onClose={() => {
            setIframeBtn(false)
			setCustomFullscreen(false)
            localStorage.setItem('iframeactive', false)
        }} />

        <CustomModal display_custom={customBtn} type={customBtnType} onClose={() => {
            setCustomBtn(false)
            setCustomBtnType(null)
        }} />

        <Modal show={showPop} onHide={handleClosePop} className="cusxm">
                    <Modal.Body className="text-center">
                    {popData && (
                        <a target="_blank" href={popData.redirection + displayLink} rel="noreferrer">
                            <img src={popData.image} className="img-fluid" alt={popData.id}  />
                        </a>
                    )}
                    </Modal.Body>
        </Modal>

        <Modal show={showPopOneTime} onHide={handleClosePopOneTime} className="cusxm">
                    <Modal.Body className="text-center w-100">
                        {/* <a target="_blank" href={`${adsLinks && createAdLink(7)}${displayLink}`}> */}
                        <a target='blank' href={`${primaryLinks && primaryLinks.modal}${displayLink}`}>
                            <img src='https://i.ibb.co/w08scND/2a58d73834a18bc0fa68a588a1a0b273.gif' className="img-fluid" />
                        </a>
                    </Modal.Body>
        </Modal>

        <Modal show={showPopC} onHide={handleClosePopC} className="cusxm">
                    <Modal.Body className="text-center">
                    {popDataC && (
                        <a target="_blank" href={`https://www.gorillatrk1.com/3J67C/CNXRZ5/${displayLink}`} rel="noreferrer">
                            <img src={popDataC.image} className="img-fluid" alt={popDataC.id}  />
                        </a>
                    )}
                    </Modal.Body>
        </Modal>

         <div className="display-mobilebb">
            <Link to="/dashboard" onClick={() => setExpanded(false)}>
            <div className="display-mobilebb-item">
                 <i className="fa-solid fa-home"></i>
            </div>
            </Link>
            <Link to="/dashboard/messages" onClick={() => setExpanded(false)}>
            <div className="display-mobilebb-item">
                 <i className="fa-solid fa-inbox"></i> 
            </div>
            </Link>
            <Link to="/dashboard/hotornot" onClick={() => setExpanded(false)}>
            <div className="display-mobilebb-item">
                 <i className="fa-solid fa-fire-flame-curved"></i> 
            </div>
            </Link>
         </div>

         <div className="dashboard-main">
                <Container className="position-relative">

                    <Row className="dashboard-row position-relative g-0"  data-aos="flip-up">
                        <Col sm={3} className="dashboard-row-col-sm-4 leftside"> 
                                <div className="dashboard-welcome">
                                    <center>
                                        <NavLink to="/dashboard">
                                        <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ maxHeight: '50px' }} alt="small logo" className="m-4 img-fluid" />
                                        </NavLink>                                    
                                    </center>
                                    <p> Welcome, <b>{auth.email}</b> </p>
                                </div>

                                <center>
                                <Link to='#' className="verify_text" variant="dark btn-sm m-2" onClick={() => {
                                            setIframeBtn(true)
                                            localStorage.setItem('iframeactive', true)
                                            setCustomFullscreen(true)
                                        }}>
                                        Verify your age to activate your FREE account
                                </Link>   
                                </center>
 
                                <Navbar expand="lg" expanded={expanded} className="db-menu-update">
                                <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(!expanded)} />
                                <Navbar.Collapse id="navbarScroll">
                                <Nav className="me-auto w-100">
                                <ul>
                                    <NavLink to="/dashboard/profile" onClick={() => setExpanded(false)}> <li> <i className="fa-solid fa-arrow-right-long custom-arrow mt-1"></i> <i className="fa-solid fa-cog"></i> &nbsp; My Profile </li> </NavLink>
                                    <NavLink to="/dashboard/members" onClick={() => setExpanded(false)}> <li> <i className="fa-solid fa-arrow-right-long custom-arrow mt-1"></i> <i className="fa-solid fa-users"></i> &nbsp; Members </li> </NavLink>
                                    <NavLink to="/dashboard/messages" onClick={() => setExpanded(false)}> <li className="position-relative"> <i className="fa-solid fa-arrow-right-long custom-arrow mt-1"></i> <i className="fa-solid fa-inbox"></i> &nbsp; Messages {messagesC > 0 ? <span className="custombadge-m">{messagesC}</span> : ''} </li> </NavLink>
                                    <NavLink to="/dashboard/matches" onClick={() => setExpanded(false)}> <li className="position-relative"> <i className="fa-solid fa-arrow-right-long custom-arrow mt-1"></i> <i className="fa-solid fa-infinity"></i> &nbsp; Matches {matchesC > 0 ? <span className="custombadge-m">{matchesC}</span> : ''} </li> </NavLink>
                                    {/* <NavLink to="/dashboard/local" onClick={() => setExpanded(false)}> <li> <i className="fa-solid fa-arrow-right-long custom-arrow mt-1"></i> <i className="fa-solid fa-location-dot"></i> &nbsp; Local girls </li> </NavLink> */}
                                    <NavLink to="/dashboard/hotornot" onClick={() => setExpanded(false)}> <li> <i className="fa-solid fa-arrow-right-long custom-arrow mt-1"></i> <i className="fa-solid fa-fire-flame-curved"></i> &nbsp; Hor or Not </li> </NavLink>
                                    {/* <NavLink to="/dashboard/visitors" onClick={() => setExpanded(false)}> <li> <i className="fa-solid fa-arrow-right-long custom-arrow mt-1"></i> <i className="fa-solid fa-eye"></i> &nbsp; My visitors {visitorsCount > 0 ? <span className="custombadge-m">{visitorsCount}</span> : ''} </li> </NavLink> */}
                                    <NavLink to="/dashboard/livecams" onClick={() => setExpanded(false)}> <li> <i className="fa-solid fa-arrow-right-long custom-arrow mt-1"></i> <i className="fa-solid fa-video"></i> &nbsp; Live Cams </li> </NavLink>
                                    <NavLink to="/dashboard/top-posts" onClick={() => setExpanded(false)}> <li> <i className="fa-solid fa-arrow-right-long custom-arrow mt-1"></i> <i className="fa-solid fa-medal"></i> &nbsp; Top posts </li> </NavLink>
                               </ul>
                               </Nav>
                               </Navbar.Collapse>
                               </Navbar>

                               <center>
                               <Button variant="dark btn-sm m-2 btn-gp" onClick={() => {
                                            setIframeBtn(true)
                                            localStorage.setItem('iframeactive', true)
                                            setCustomFullscreen(true)
                                }}>
                                Get Premium
                               </Button>

                               {/* <a target={"_blank"} href={`${adsLinks && createAdLink(8)}${displayLink}`} rel="noreferrer"> */}
                               <a target='_blank' href={`${primaryLinks && primaryLinks.fucknow}${displayLink}`} rel="noreferrer">
                               <Button variant="dark btn-sm m-2 btn-fn">
                                Fuck Now
                               </Button>
                               </a>

                               <a target={"_blank"} href={`https://www.gcdates.com/3J67C/26S3GX3/${link}`} rel="noreferrer">
                               <Button variant="dark btn-sm m-2 btn-lc">
                                Live Cams
                               </Button>
                               </a>
                               </center>

                               <DisplayAds position="dashboard_leftsidebar_bottom" />
                         </Col>
                        <Col sm={7} className="dashboard-row-col-sm-8 position-relative"> 
                                <DisplayAds position="dashboard_contenttop" />

                                {dashboardroutes}                                 

                                <DisplayAds position="dashboard_contentbottom" />
                        </Col>
                        <Col sm={2}>
                               <center> <DisplayAds position="dashboard_rightsidebar" /> </center>
                        </Col>
                    </Row>

                    <div className="dashboard-footer">
                               <hr />
                               © {currentYear} <b>onenightconfidential.com</b> <br /> All rights reserved.

                               <center>
                               <Link to="/dashboard/terms">
                                <Button variant="dark btn-sm m-2">
                               Terms and Conditions
                               </Button>
                               </Link>

                               <Link to="/dashboard/privacypolicy">
                               <Button variant="dark btn-sm m-2">
                               Privacy Policy
                               </Button>
                               </Link>

                               <a href="https://gorilla.cash" target="_blank">
                               <Button variant="dark btn-sm m-2">
                               Affiliate Program
                               </Button>                                
                               </a>
                               </center>
                    </div>

                </Container>
         </div>

        </>
    )
}  

export default Dashboard;