import React, { useState, useEffect } from 'react';

import { Modal, Row, Col, Button } from 'react-bootstrap';

import axios from 'axios';

const IframeModal = (props) => {
    const [showIframe, setShowIframe] = useState(false);
    const [iframeData, setIframeData] = useState();

    const handleShowIframe = () => {
        setShowIframe(true);
        fetchIframe()
    }

    const handleCloseIframe = () => {
        setShowIframe(false);
        props.onClose();
        localStorage.setItem('iframeactive', false)
    }

    useEffect(() => {
        if(props.display === true) handleShowIframe()
    }, [props]);

    const getUser = JSON.parse(localStorage.getItem('user'));
    
    const fetchIframe = async () => {
        try {
            const responseData = await axios.get('https://gorillamediallc.com/onenightserver/stored/data/iframes_list.json');
            const filterData = responseData.data.filter((iframe) => iframe.display === true);

            setIframeData(filterData[0])

        } catch (err) {
            console.log(err)
        }
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);  

    const transaction_id = urlParams.get("aff_sub") || localStorage.getItem('transaction_id')
    
    const aff_sub1 = urlParams.get("aff_sub1") || localStorage.getItem('aff_sub1')
    const aff_sub2 = urlParams.get("aff_sub2") || localStorage.getItem('aff_sub2')
    const aff_sub3 = urlParams.get("aff_sub3") || localStorage.getItem('aff_sub3')
    const aff_sub4 = urlParams.get("aff_sub4") || localStorage.getItem('aff_sub4')
    const aff_sub5 = urlParams.get("aff_sub5") || localStorage.getItem('aff_sub5')
    const link = `?sub1=${aff_sub1}&sub2=${aff_sub2}&sub3=${aff_sub3}&sub4=${getUser?.email}&sub5=${aff_sub5}&transaction_id=${transaction_id}`;

    return (
        <React.Fragment>
        {props.display && props.custom_fullscreen ? (
            <>
            <Modal show={showIframe} fullscreen={props.custom_fullscreen} onHide={() => handleCloseIframe()} className="cusxm iframe_embed_css">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <iframe src={`https://track.gorillatracker.com/f02439d5-b938-44b8-88a5-eb1864be1743${link}`} className="w-100 h-100" title="Iframe"></iframe> 
                </Modal.Body>
            </Modal>
            </>
        ) : (
            <>
            <Modal size={iframeData && iframeData.type === 1 ? 'lg' : 'xl'} fullscreen={iframeData && iframeData.type === 1 ? true : false} className="custom-iframemodal" show={showIframe} onHide={handleCloseIframe}>
                        <Modal.Header closeButton>
                        <Modal.Title className="custom-headermodal w-50">{iframeData && iframeData.title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className={iframeData && iframeData.type === 1 ? 'new-iframebody' : ''}>
                                {
                                    iframeData && iframeData.type === 1 ? (
                                        <>
                                        <center>
                                                <img src={`${process.env.PUBLIC_URL}/images/logo.png`}  style={{ maxHeight: '80px' }} alt="small logo" className="m-4 img-fluid new-iframebody-logo" />
                                                <h2>VERIFY THAT YOU ARE OVER 18!</h2>
                                                <h3>$0.00 No Charge!</h3>
                                        </center>                    
                                    
                                        <Row className="m-4">
                                            <Col sm={4} style={{ height: "auto" }}>
                                                    <>
                                                    <p className="text-center">100% FREE - Please verify your age with a credit card to activate your free account</p>
                                                    {iframeData && (
                                                        <iframe src={iframeData?.url} style={{ minHeight: "700px" }} className="w-100 h-100" title="AD"></iframe> 
                                                    )}
                                                    </>
                                            </Col>
                                            <Col sm={8}>
                                                <div className="new-iframebody-rightside">
                                                        <div className="new-iframebody-rightside-title"> Your Membership Includes:</div>

                                                        <hr />

                                                        <ul>
                                                            <li>LIVE Chat Instant Messenger</li>
                                                            <li>View & Upload Unlimited Pics</li>
                                                            <li>Send & Receive Emails</li>
                                                            <li>Featured Profile Highlighting</li>
                                                            <li>TOP Search Results Placement</li>
                                                            <li>Smart Match Detection</li>
                                                            <li>Mobile & Tablet Compatible</li>
                                                        </ul>
                                                </div>

                                                <Row className="mt-4">
                                                    <Col sm={4}>
                                                            <div className="new-iframebody-rightside-block">
                                                                    <h1><i className="fa-solid fa-shield-halved"></i></h1>
                                                                    <h2>Secure</h2>
                                                                    <p>We Use 256 Bit Encryption</p>
                                                            </div>
                                                    </Col>

                                                    <Col sm={4}>
                                                            <div className="new-iframebody-rightside-block">
                                                                    <h1><i className="fa-solid fa-key"></i></h1>
                                                                    <h2>Safe</h2>
                                                                    <p>100% Safe, Private and Secure</p>
                                                            </div>
                                                    </Col>

                                                    <Col sm={4}>
                                                            <div className="new-iframebody-rightside-block">
                                                                    <h1><i className="fa-solid fa-gear"></i></h1>
                                                                    <h2>Private</h2>
                                                                    <p>Your Privacy is Guaranteed</p>
                                                            </div>
                                                    </Col>
                                                </Row>
                                                
                                            </Col>
                                        </Row>                            
                                        </>
                                    ) : (
                                        <>
                                        <iframe src={iframeData?.url} className="w-100 h-100" title="Iframe"></iframe> 
                                        </>
                                    )
                                }
                            
                        </Modal.Body>
                    </Modal>            
            </>
        )}
        </React.Fragment>
    )
}

export default IframeModal;